
import Base from './Base';

class App extends Base {
	ready(){

    }



}

export default new App();