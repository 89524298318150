import App from "./js/App";
import Analytics from "./js/Analytics";

window.Log = (...args) => {
    if ( !wp_dark_mode_json.debug ) return;
    console.log('%cWPDarkMode', 'background: #2663eb; color: #fff; padding: 2px 5px; border-radius: 3px; font-weight: 500;', ...args)
};

(() => {
    App.init();
    Analytics.init();
})()